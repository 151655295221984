export const LIST_PROMO_REDUCER_PENDING = 'LIST_PROMO_REDUCER_PENDING';
export const LIST_PROMO_REDUCER_FULFILLED = 'LIST_PROMO_REDUCER_FULFILLED';
export const LIST_PROMO_REDUCER_REJECTED = 'LIST_PROMO_REDUCER_REJECTED';
export const SEARCH_PROMO = 'SEARCH_PROMO';
export const PROPERTIES_PROMO = 'PROPERTIES_PROMO';
export const DETAIL_PROMO_REDUCER_PENDING = 'DETAIL_PROMO_REDUCER_PENDING';
export const DETAIL_PROMO_REDUCER_FULFILLED = 'DETAIL_PROMO_REDUCER_FULFILLED';
export const DETAIL_PROMO_REDUCER_REJECTED = 'DETAIL_PROMO_REDUCER_REJECTED';
export const PROPERTIES_PROMO_CODE_PENDING = 'PROPERTIES_PROMO_CODE_PENDING';
export const PROPERTIES_PROMO_CODE_FULFILLED = 'PROPERTIES_PROMO_CODE_FULFILLED';
export const PROPERTIES_PROMO_CODE_REJECTED = 'PROPERTIES_PROMO_CODE_REJECTED';

const ListPromoState = {
  isLoading: false,
  error: false,
  data: [],
  V_PROMO_NAME: '',
};

export function ListPromoReducer(state = ListPromoState, action) {
  switch (action.type) {
    case LIST_PROMO_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, data: [],
      };
    case LIST_PROMO_REDUCER_FULFILLED:
      return { ...state, isLoading: false, data: action.payload };
    case LIST_PROMO_REDUCER_REJECTED:
      return { ...state, isLoading: false, error: true };
    case SEARCH_PROMO:
      return { ...state, V_PROMO_NAME: action.payload };
    default:
      return state;
  }
}

const PropertiesPromo = {
  data: {},
  isItem: false,
};

export function PropertiesPROMO(state = PropertiesPromo, action) {
  switch (action.type) {
    case PROPERTIES_PROMO:
      return {
        ...state, data: action.payload, isItem: true,
      };
    default:
      return state;
  }
}

const DetailPromoState = {
  isLoading: false,
  error: false,
  master: {},
  code: [],
};

export function DetailPromoReducer(state = DetailPromoState, action) {
  switch (action.type) {
    case DETAIL_PROMO_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, master: {}, code: [],
      };
    case DETAIL_PROMO_REDUCER_FULFILLED:
      return {
        ...state, isLoading: false, master: action.master, code: action.code,
      };
    case LIST_PROMO_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, master: {}, code: [],
      };
    default:
      return state;
  }
}

const PropertiesPromoCodeState = {
  isLoading: false,
  error: false,
  promoCode: {},
};

export function PropertyPromoCode(state = PropertiesPromoCodeState, action) {
  switch (action.type) {
    case PROPERTIES_PROMO_CODE_PENDING:
      return {
        ...state, isLoading: true, error: false, promoCode: {},
      };
    case PROPERTIES_PROMO_CODE_FULFILLED:
      return {
        ...state, isLoading: false, promoCode: action.payload,
      };
    case PROPERTIES_PROMO_CODE_REJECTED:
      return {
        ...state, isLoading: false, error: true, promoCode: {},
      };
    default:
      return state;
  }
}

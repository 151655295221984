import React, { Suspense, useEffect } from 'react';
import './App.css';
import {
  Switch,
  Route,
  BrowserRouter,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
// store
import Redux from './redux/store';
// variable
import ROUTERS from './routes';
// component
import PrivateApp from './PrivateApp';
import NotMatchLink from './screens/NotMatchLink';
import 'react-calendar/dist/Calendar.css';

if (process.env.NODE_ENV === 'development') {
  // Redux.store.subscribe(() => {
  //   eslint-disable-next-line no-console
  //   console.log('perform', performance.now());
  // });
}
/**
 * @function App adalah main screen yang bertanggung jawab atas routing dan fungsi core lainnya
 * @see PrivateApp component di './PrivateApp'
 * @see ROUTERS variable di './ROUTERS'
 * @see Redux store di './redux/store'
 */
function App() {
  // debug time open application
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.time('Open Screen');
  }
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.timeEnd('Open Screen');
    }
  }, []);
  return (
    <Suspense fallback={<>loading</>}>
      <Provider store={Redux.store}>
        <PersistGate loading={null} persistor={Redux.persistor}>
          <BrowserRouter>
            <Switch>
              {
                ROUTERS.map(({ isPrivate, Component, ...route }, i) => {
                  if (isPrivate) {
                    return (
                      <PrivateApp key={i} {...route}>
                        <Component/>
                      </PrivateApp>
                    );
                  }
                  return (
                    <Route key={i} {...route}>
                      <Component/>
                    </Route>
                  );
                })
              }
              <Route path="*">
                <NotMatchLink />
              </Route>
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;

import React from 'react';
// import HomeScreen from './modules/Home';
// import LoginScreen from './modules/Login';
// import WrapperBatch from './modules/WrapperBatch';
// screen
const WrapperScreen = React.lazy(() => import('./screens/Wrapper'));
const LoginScreen = React.lazy(() => import('./screens/Login'));
const NotMatchLink = React.lazy(() => import('./screens/NotMatchLink'));
// const ErrorLoad = React.lazy(() => import('./modules/ErrorLoad'));

/**
 * @var ROUTERS adalah variable router aplikasi
 */
const ROUTERS = [
  {
    path: '/404',
    Component: NotMatchLink,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/login',
    Component: LoginScreen,
    isPrivate: false,
  },
  {
    path: '/:a',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/:a/:b',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/:a/:b/:c',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/:a/:b/:c/:d',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/:a/:b/:c/:d/:e',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/:a/:b/:c/:d/:e/:f',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/',
    Component: WrapperScreen,
    exact: true,
    isPrivate: true,
  },
  // {
  //   path: '/error',
  //   Component: ErrorLoad,
  //   isPrivate: false,
  // },
];

export default ROUTERS;

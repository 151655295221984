/**
 * @var WHITE_LIST is variable to declare store reducer save in browser;
 */
const WHITE_LIST = [
  'PropertyRootPageReducer',
  'PropertyBatchesPageReducer',
  'PropertyCompanyPageReducer',
  'PropertyCandidatesPageReducer',
  'PropertyCandidatePageReducer',
  'PropertyBatchMonitorPageReducer',
  'ListCompanyReducer',
  'ListBatchesPageReducerScrappe',
  'PropertyIDCogsCopamyReducer',
  'PropertyIDCogsWOReducer',
  'PropertyIDCogsWOFilterReducer',
  'PropertyIDCogsBatchReducer',
  'PropertyIDCogsBatchFilterReducer',
  'PropertyIDCogsLinguistikhReducer',
  'PropertyWorkorderPageReducer',
  'PropertyDetailWorkorderPageReducer',
  'PropertyDefaultPriceReducer',
  'PropertyBatchNewStateReducer',
  'DataChekedReducer',
  'DataChekedReducerFacebook',
  'DataChekedReducerInstagram',
];

const WHITE_LIST_REDUCER = [
  'PROPERTY_ROOT_PAGE',
  'PROPERTY_BATCHES_PAGE',
  'PROPERTY_CANDIDATES_PAGE',
  'PROPERTY_CANDIDATE_PAGE',
  'PROPERTY_BATCH_MONITOR_PAGE',
  'PROPERTY_WORKORDER_PAGE',
  'PROPERTY_DETAIL_WORKORDER_PAGE',
];

export default {
  WHITE_LIST,
  WHITE_LIST_REDUCER,
};

const ActionState = {
  isLoading: false,
  error: false,
};
const ListState = {
  ...ActionState,
  result: {
    data: {},
  },
  dataCount: 0,
};
const CountState = {
  ...ActionState,
  result: {
    V_FACEBOOK: 0,
    INSTAGRAM: 0,
    TWITTER: 0,
  },
};
export function CountDataLinguisticAssignReducer(state = CountState, action) {
  switch (action.type) {
    case 'COUNT_DATA_LINGUISTIC_ASSIGN_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: {},
      };
    case 'COUNT_DATA_LINGUISTIC_ASSIGN_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'COUNT_DATA_LINGUISTIC_ASSIGN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function LinguisticAssignReducer(state = ListState, action) {
  switch (action.type) {
    case 'LINGUISTIC_ASSIGN_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'LINGUISTIC_ASSIGN_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.data,
          },
        },
        dataCount: action.dataCount,
      };
    case 'LINGUISTIC_ASSIGN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

import React, { useCallback } from 'react';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import Image404 from '../../dist/404.svg';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100vh',
    background: '#F9FAFC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperView: {
    width: 'calc(100% - 100px)',
    height: 'calc(100% - 100px)',
    background: 'white',
    borderRadius: 20,
    overflow: 'hidden',
  },
  wrapperImage: {
    background: '#E9EFFC',
    height: '100%',
  },
  titleError: {
    color: '#CA302D',
  },
  spanError: {
    color: '#8D8D8D',
    margin: 0,
  },
  buttonError: {
    borderRadius: '34px',
    boxShadow: 'none',
    textTransform: 'none',
    background: '#222E61',
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      background: '#222e61db',
      boxShadow: 'none',
    },
  },
});
function NotMatchLink({
  history: router,
}) {
  const classes = useStyles();
  const handleChangePage = useCallback(() => {
    router.replace('/');
  }, [router]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperView}>
        <Grid
          container
          item
          direction="row"
          style={{ height: '100%' }}
        >
          <Grid
            container
            item
            direction="column"
            xs={6}
            alignItems='center'
            justify='center'
            style={{ height: '100%' }}
          >
            <h2 className={classes.titleError}>
              ERROR 404
            </h2>
            <p className={classes.spanError}>
              Halaman tidak ditemukan. Silahkan cek link yang Anda masukan.
            </p>
            <br/>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonError}
              onClick={handleChangePage}
              type="submit">
              <b style={{ margin: '0 20px' }} >Kembali</b>
            </Button>
          </Grid>
          <Grid
            container
            item
            direction="row"
            xs={6}
            alignItems='center'
            justify='center'
            className={classes.wrapperImage}
          >
            {/* <img alt="" src={Image404}/> */}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withRouter(NotMatchLink);

import React from 'react';
import {
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

/**
 * @function PivateApp adalah screen pemisah antara screen yang butuh authentification dan tidak
 * @property statusPage, children, rest
 * @see PivateApp.propTypes untuk melihat type data
 */
function PivateApp({
  propertyRootPageReducer,
  rest,
  children,
}) {
  return (
    <Route
      {...rest}
      render={() => (propertyRootPageReducer.isAuthenticated ? (
        children
      ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
      ))
      }
    />
  );
}

const mapsStateToProps = (state) => ({
  propertyRootPageReducer: state.PropertyRootPageReducer,
});
const mapsDispatchToProps = {
};
export default compose(
  withRouter,
  React.memo,
  connect(mapsStateToProps, mapsDispatchToProps),
)(PivateApp);

const ActionState = {
  isLoading: false,
  error: false,
};
const ListCompanyState = {
  ...ActionState,
  result: {
    data: [],
    dataCount: 0,
  },
};
const BatchNewState = {
  ...ActionState,
  data: {
    C_CLIENT_ID: '',
    C_BATCH_ID: '',
    V_BATCH_NAME: '',
    D_START_DATE: '',
    D_END_DATE: '',
  },
  typeUpload: '',
};
const ListBatchState = {
  ...ActionState,
  result: {
    data: {},
    dataCount: 0,
  },
};
const CountBatchState = {
  ...ActionState,
  result: {
    all: 0,
    request: 0,
    onProgress: 0,
    finish: 0,
  },
};
const ListState = {
  ...ActionState,
  result: {
    data: [],
    dataCount: 0,
  },
};

const ListProjectState = {
  ...ActionState,
  result: {
    data: [],
    dataCount: 0,
  },
};

const DataListState = {
  ...ActionState,
  result: [],
};
const DataObjectState = {
  ...ActionState,
  result: {},
};

const ValidationExcelAddState = {
  ...ActionState,
  data: [],
};

const ValidationExcelEditState = {
  ...ActionState,
  data: [],
};

export function ValidationExcelAddReducer(state = ValidationExcelAddState, action) {
  switch (action.type) {
    case 'LIST_VALIDATION_EXCEL_ADD_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, data: [],
      };
    case 'LIST_VALIDATION_EXCEL_ADD_REDUCER_FULFILLED':
      return { ...state, isLoading: false, data: action.payload };
    case 'LIST_VALIDATION_EXCEL_ADD_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ValidationExcelEditReducer(state = ValidationExcelEditState, action) {
  switch (action.type) {
    case 'LIST_VALIDATION_EXCEL_EDIT_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, data: [],
      };
    case 'LIST_VALIDATION_EXCEL_EDIT_REDUCER_FULFILLED':
      return { ...state, isLoading: false, data: action.payload };
    case 'LIST_VALIDATION_EXCEL_EDIT_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PropertyBatchNewStateReducer(state = BatchNewState, action) {
  switch (action.type) {
    case 'SET_BatchNewState':
      return {
        ...state,
        data: action.data,
        typeUpload: '',
      };
    case 'RESET_BatchNewState':
      return {
        ...state,
        data: '',
        typeUpload: '',
      };
    case 'TYPE_UPLOAD_BatchNewState':
      return {
        ...state,
        typeUpload: action.typeUpload,
      };
    default:
      return state;
  }
}
export function ListCompanyReducer(state = ListCompanyState, action) {
  switch (action.type) {
    case 'LIST_COMPANY_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'LIST_COMPANY_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_COMPANY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ListBatchReducer(state = ListBatchState, action) {
  switch (action.type) {
    case 'LIST_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'LIST_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'LIST_BATCH_REDUCER_CHANGE':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            [action.payload.index]: {
              ...state.result.data[action.payload.index], ...action.payload.data,
            },
          },
        },
      };
    case 'LIST_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'LIST_BATCH_REDUCER_RESET_ALL':
      return ListBatchState;
    default:
      return state;
  }
}
export function CountBatchReducer(state = CountBatchState, action) {
  switch (action.type) {
    case 'COUNT_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'COUNT_BATCH_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'COUNT_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ListCandidateReducer(state = ListState, action) {
  switch (action.type) {
    case 'LIST_CANDIDATE_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'LIST_CANDIDATE_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'LIST_CANDIDATE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'LIST_CANDIDATE_REDUCER_RESET_ALL':
      return ListState;
    default:
      return state;
  }
}
export function ListLinguisticReducer(state = ListState, action) {
//  console.log('_batchesReducerListLinguisticReducer',ListLinguisticReducer);
  switch (action.type) {
    case 'LIST_LINGUISTIC_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'LIST_LINGUISTIC_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_LINGUISTIC_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ListQuotaWorkOrderReducer(state = ListState, action) {
  //  console.log('_batchesReducerListLinguisticReducer',ListLinguisticReducer);
  switch (action.type) {
    case 'LIST_QUOTAWORKORDER_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'LIST_QUOTAWORKORDER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_QUOTAWORKORDER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ListWorkorderReducer(state = ListState, action) {
  // console.log('_batchesReducerListWorkorderReducer',ListLinguisticReducer);
  switch (action.type) {
    case 'LIST_WORKORDER_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'LIST_WORKORDER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_WORKORDER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function DetailWorkorderReducer(state = ListState, action) {
  // console.log('_batchesReducerListWorkorderReducer',ListLinguisticReducer);
  switch (action.type) {
    case 'DETAIL_WORKORDER_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'DETAIL_WORKORDER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DETAIL_WORKORDER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function PaymentLinguisticLVReducer(state = ListState, action) {
  switch (action.type) {
    case 'PAYMENT_LINGUISTIC_LV_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'PAYMENT_LINGUISTIC_LV_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'PAYMENT_LINGUISTIC_LV_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PaymentLinguisticAVReducer(state = ListState, action) {
  switch (action.type) {
    case 'PAYMENT_LINGUISTIC_AV_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'PAYMENT_LINGUISTIC_AV_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'PAYMENT_LINGUISTIC_AV_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PaymentLinguisticAVHistoryReducer(state = ListState, action) {
  switch (action.type) {
    case 'PAYMENT_LINGUISTIC_AV_HISTORY_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'PAYMENT_LINGUISTIC_AV_HISTORY_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'PAYMENT_LINGUISTIC_AV_HISTORY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ListEmailReducer(state = DataListState, action) {
  switch (action.type) {
    case 'LIST_EMAIL_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'LIST_EMAIL_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_EMAIL_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function QoutaDetailReducer(state = DataListState, action) {
  switch (action.type) {
    case 'QOUTA_DETAIL_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'QOUTA_DETAIL_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'QOUTA_DETAIL_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function QoutaCheckReducer(state = DataObjectState, action) {
  switch (action.type) {
    case 'QOUTA_CHECK_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'QOUTA_CHECK_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'QOUTA_CHECK_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ListProjectWorkorderReducer(state = ListProjectState, action) {
  // console.log('_batchesReducerListWorkorderReducer',ListLinguisticReducer);
  switch (action.type) {
    case 'LIST_PROJECT_WORKORDER_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: 0 },
      };
    case 'LIST_PROJECT_WORKORDER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_PROJECT_WORKORDER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

const PropertyRootPageState = {
  isAuthenticated: false,
  token: null,
  username: null,
  role: null,
};
const PropertyBatchPageState = {
  C_CLIENT_ID: '',
  V_FULL_NAME: '',
  V_WORK_EMAIL: '',
  V_TOTAL_BATCH: '',
};
const PropertyCompanyPageState = {
  C_CLIENT_ID: '',
  companyName: '',
};
const ListBatchesPageReducerScrappeState = {
  isLoading: false,
  error: false,
  data: [],
  selectBatch: [],
};

const PropertyWorkorderPageState = {
  C_CLIENT_ID: '',
  V_FULL_NAME: '',
  V_WORK_EMAIL: '',
  V_TOTAL_BATCH: '',
};

const PropertyDetailWOPageState = {
  C_CLIENT_ID: '',
  V_FULL_NAME: '',
  V_WORK_EMAIL: '',
};

const PropertyCandidatesPageState = {
  C_BATCH_ID: '',
};
const PropertyCandidatePageState = {
  C_PERSONAL_ID: '',
  C_STATUS_ID: false,
  FACEBOOK_AVAILABILITY: false,
  INSTAGRAM_AVAILABILITY: false,
  LINKEDIN_AVAILABILITY: false,
  TWITTER_AVAILABILITY: false,
};
const PropertyUploadPageState = {
  sosmed: '',
};
const PropertyBatchMonitorPageState = {
  C_BATCH_ID: '',
};
export function PropertyRootPageReducer(state = PropertyRootPageState, action) {
  switch (action.type) {
    case 'PROPERTY_ROOT_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_ROOT_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_ROOT_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_ROOT_PAGE_REDUCER_RESET_ALL':
      return PropertyRootPageState;
    default:
      return state;
  }
}
export function PropertyBatchesPageReducer(state = PropertyBatchPageState, action) {
  switch (action.type) {
    case 'PROPERTY_BATCHES_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_BATCHES_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_BATCHES_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_BATCHES_PAGE_REDUCER_RESET_ALL':
      return PropertyBatchPageState;
    default:
      return state;
  }
}

export function PropertyCompanyPageReducer(state = PropertyCompanyPageState, action) {
  switch (action.type) {
    case 'PROPERTY_COMPANY_REDUCER_SET':
      return {
        ...state,
        C_CLIENT_ID: action.payload,
        companyName: action.companyName,
      };
    case 'PROPERTY_COMPANY_REDUCER_RESET_ALL':
      return PropertyCompanyPageState;
    default:
      return state;
  }
}

export function PropertyWorkorderPageReducer(state = PropertyWorkorderPageState, action) {
  switch (action.type) {
    case 'PROPERTY_WORKORDER_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_WORKORDER_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_WORKORDER_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_WORKORDER_PAGE_REDUCER_RESET_ALL':
      return PropertyWorkorderPageState;
    default:
      return state;
  }
}

export function PropertyDetailWorkorderPageReducer(state = PropertyDetailWOPageState, action) {
  switch (action.type) {
    case 'PROPERTY_DETAIL_WORKORDER_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_DETAIL_WORKORDER_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_DETAIL_WORKORDER_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_DETAIL_WORKORDER_PAGE_REDUCER_RESET_ALL':
      return PropertyDetailWOPageState;
    default:
      return state;
  }
}

export function PropertyCandidatesPageReducer(state = PropertyCandidatesPageState, action) {
  switch (action.type) {
    case 'PROPERTY_CANDIDATES_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_CANDIDATES_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_CANDIDATES_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_CANDIDATES_PAGE_REDUCER_RESET_ALL':
      return PropertyBatchPageState;
    default:
      return state;
  }
}
export function PropertyCandidatePageReducer(state = PropertyCandidatePageState, action) {
  switch (action.type) {
    case 'PROPERTY_CANDIDATE_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_CANDIDATE_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_CANDIDATE_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_CANDIDATE_PAGE_REDUCER_RESET_ALL':
      return PropertyBatchPageState;
    default:
      return state;
  }
}
export function PropertyUploadPageReducer(state = PropertyUploadPageState, action) {
  switch (action.type) {
    case 'PROPERTY_UPLOAD_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_UPLOAD_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_UPLOAD_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_UPLOAD_PAGE_REDUCER_RESET_ALL':
      return PropertyBatchPageState;
    default:
      return state;
  }
}
export function PropertyBatchMonitorPageReducer(state = PropertyBatchMonitorPageState, action) {
  switch (action.type) {
    case 'PROPERTY_BATCH_MONITOR_PAGE_REDUCER_SET':
      return { ...state, [action.payload.nameOfKey]: action.payload.valueOfKey };
    case 'PROPERTY_BATCH_MONITOR_PAGE_REDUCER_SET_MULTIPLE':
      return { ...state, ...action.payload };
    case 'PROPERTY_BATCH_MONITOR_PAGE_REDUCER_RESET':
      return { ...state, [action.payload.nameOfKey]: null };
    case 'PROPERTY_BATCH_MONITOR_PAGE_REDUCER_RESET_ALL':
      return PropertyBatchPageState;
    default:
      return state;
  }
}

export function ListBatchesPageReducerScrappe(state = ListBatchesPageReducerScrappeState, action) {
  switch (action.type) {
    case 'SET_LIST_BATCH_SCRAPPE_REDUCER_SET':
      return { ...state, isLoading: true, error: false };
    case 'SET_LIST_BATCH_SCRAPPE_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.payload,
      };
    case 'SET_LIST_BATCH_SCRAPPE_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case 'SET_LIST_BATCH_SCRAPPE_SELECT_BATCH':
      return { ...state, selectBatch: action.payload };
    case 'RESET_LIST_BATCH_SCRAPPE_REDUCER_SET':
      return ListBatchesPageReducerScrappeState;
    default:
      return state;
  }
}

const ActionState = {
  isLoading: false,
  error: false,
};
const MasterState = {
  ...ActionState,
  result: [],
};

const PropertyIDCogsCopamy = {
  ...ActionState,
  id: '',
  start: '',
  end: '',
};

const PropertyIDCogsWO = {
  ...ActionState,
  id: '',
  start: '',
  search: '',
  V_WO_NAME: '',
  end: '',
  result: [],
};

const PropertyIDCogsBatch = {
  ...ActionState,
  quotaId: '',
  clientId: '',
  start: '',
  search: '',
  V_WO_NAME: '',
  end: '',
  result: [],
};

const PropertyIDCogsLinguistik = {
  ...ActionState,
  quotaId: '',
  batchId: '',
  result: [],
};

const PropertyDefaultPrice = {
  ...ActionState,
  result: 0,
};

const PropertyIDCogsWOFilter = {
  ...ActionState,
  result: [],
  searchCompany: {},
};

const PropertyIDCogsBatchFilter = {
  ...ActionState,
  result: [],
  searchCompany: {},
};

export function PropertyIDCogsBatchFilterReducer(state = PropertyIDCogsBatchFilter, action) {
  switch (action.type) {
    case 'SET_LIST_FILTER_BATCH':
      return {
        ...state, result: action.result,
      };
    case 'SET_FILTER_BATCH':
      return {
        ...state, searchCompany: action.searchCompany,
      };
    case 'RESET_LIST_FILTER_BATCH':
      return {
        ...state, searchCompany: {},
      };
    default:
      return state;
  }
}

export function PropertyIDCogsWOFilterReducer(state = PropertyIDCogsWOFilter, action) {
  switch (action.type) {
    case 'SET_LIST_FILTER':
      return {
        ...state, result: action.result,
      };
    case 'SET_FILTER':
      return {
        ...state, searchCompany: action.searchCompany,
      };
    case 'RESET_LIST_FILTER':
      return {
        ...state, searchCompany: {},
      };
    default:
      return state;
  }
}

export function PropertyDefaultPriceReducer(state = PropertyDefaultPrice, action) {
  switch (action.type) {
    case 'SET_DEFAULT_PRICE':
      return {
        ...state, result: action.payload,
      };
    case 'RESET_DEFAULT_PRICE':
      return {
        ...state, result: '',
      };
    default:
      return state;
  }
}

export function PropertyIDCogsCopamyReducer(state = PropertyIDCogsCopamy, action) {
  switch (action.type) {
    case 'SET_ID_COGS_COMPANY':
      return {
        ...state, id: action.payload,
      };
    case 'RESET_ID_COGS_COMPANY':
      return {
        ...state, id: '',
      };
    default:
      return state;
  }
}

export function PropertyIDCogsWOReducer(state = PropertyIDCogsWO, action) {
  switch (action.type) {
    case 'SET_ID_COGS_WO':
      return {
        ...state, id: action.payload,
      };
    case 'RESET_ID_COGS_WO':
      return {
        ...state, id: '',
      };
    case 'SET_WO_NAME':
      return {
        ...state,
        V_WO_NAME: action.V_WO_NAME,
      };
    case 'SET_COMPANY_NAME':
      return {
        ...state,
        search: action.search,
      };
    case 'RESET_COMPANY_NAME':
      return {
        ...state,
        search: '',
      };
    case 'SET_COGS_WO_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'SET_COGS_WO_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'SET_COGS_WO_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function PropertyIDCogsBatchReducer(state = PropertyIDCogsBatch, action) {
  switch (action.type) {
    case 'SET_ID_BATCH_WO':
      return {
        ...state,
        quotaId: action.quotaId,
        clientId: action.clientId,
      };
    case 'RESET_ID_BATCH_WO':
      return {
        ...state,
        quotaId: '',
        clientId: '',
      };
    case 'SET_WO_NAME_BATCH':
      return {
        ...state,
        V_WO_NAME: action.V_WO_NAME,
      };
    case 'SET_COMPANY_NAME_BATCH':
      return {
        ...state,
        search: action.search,
      };
    case 'RESET_COMPANY_NAME_BATCH':
      return {
        ...state,
        search: '',
      };
    case 'SET_BATCH_WO_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'SET_BATCH_WO_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'SET_BATCH_WO_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function PropertyIDCogsLinguistikhReducer(state = PropertyIDCogsLinguistik, action) {
  switch (action.type) {
    case 'SET_ID_COGS_LINGUISTIK':
      return {
        ...state,
        quotaId: action.quotaId,
        batchId: action.batchId,
      };
    case 'RESET_ID_COGS_LINGUISTIK':
      return {
        ...state,
        quotaId: '',
        batchId: '',
      };
    case 'SET_COGS_LINGUISTIK_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'SET_COGS_LINGUISTIK_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'SET_COGS_LINGUISTIK_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MasterInterestReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_INTEREST_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_INTEREST_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_INTEREST_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterLanguageReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_LANGUAGE_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_LANGUAGE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_LANGUAGE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterNegativityReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_NEGATIVITY_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_NEGATIVITY_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_NEGATIVITY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterPoliticalPartyReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_POLITICAL_PARTY_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_POLITICAL_PARTY_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_POLITICAL_PARTY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterPinDataReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_PIN_DATA_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_PIN_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_PIN_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MasterCompanyTypeReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_COMPANYTYPE_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_COMPANYTYPE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_COMPANYTYPE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MasterJobListReducer(state = MasterState, action) {
  switch (action.type) {
    case 'MASTER_JOBLIST_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: [],
      };
    case 'MASTER_JOBLIST_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MASTER_JOBLIST_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

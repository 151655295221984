const notificationState = {
  isLoading: false,
  erorr: false,
  results: [],
  LIMIT: 20,
};

export function ListNotificationReducer(state = notificationState, action) {
  switch (action.type) {
    case 'LIST_NOTIFICATION_REDUCER_PENDING':
      return { ...state, isLoading: true, erorr: false };
    case 'LIST_NOTIFICATION_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        erorr: false,
        results: action.payload,
      };
    case 'LIST_NOTIFICATION_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        erorr: true,
      };
    case 'LIST_NOTIFICATION_LOAD_MORE':
      return {
        ...state,
        LIMIT: state.LIMIT + 20,
      };
    default:
      return state;
  }
}
export const a = '';

export const TWITTER_NUMBER_REDUCER_PENDING = 'TWITTER_NUMBER_REDUCER_PENDING';
export const TWITTER_NUMBER_REDUCER_FULLFILLED = 'TWITTER_NUMBER_REDUCER_FULLFILLED';
export const TWITTER_NUMBER_REDUCER_REJECTED = 'TWITTER_NUMBER_REDUCER_REJECTED';
export const FACEBOOK_NUMBER_REDUCER_PENDING = 'FACEBOOK_NUMBER_REDUCER_PENDING';
export const FACEBOOK_NUMBER_REDUCER_FULLFILLED = 'FACEBOOK_NUMBER_REDUCER_FULLFILLED';
export const FACEBOOK_NUMBER_REDUCER_REJECTED = 'FACEBOOK_NUMBER_REDUCER_REJECTED';
export const INSTAGRAM_NUMBER_REDUCER_PENDING = 'INSTAGRAM_NUMBER_REDUCER_PENDING';
export const INSTAGRAM_NUMBER_REDUCER_FULLFILLED = 'INSTAGRAM_NUMBER_REDUCER_FULLFILLED';
export const INSTAGRAM_NUMBER_REDUCER_REJECTED = 'INSTAGRAM_NUMBER_REDUCER_REJECTED';
export const LINKEDIN_NUMBER_REDUCER_PENDING = 'LINKEDIN_NUMBER_REDUCER_PENDING';
export const LINKEDIN_NUMBER_REDUCER_FULLFILLED = 'LINKEDIN_NUMBER_REDUCER_FULLFILLED';
export const LINKEDIN_NUMBER_REDUCER_REJECTED = 'LINKEDIN_NUMBER_REDUCER_REJECTED';
export const INSTAGRAM_LIST_SESSION_REDUCER_PENDING = 'INSTAGRAM_LIST_SESSION_REDUCER_PENDING';
export const INSTAGRAM_LIST_SESSION_REDUCER_FULLFILLED = 'INSTAGRAM_LIST_SESSION_REDUCER_FULLFILLED';
export const INSTAGRAM_LIST_SESSION_REDUCER_REJECTED = 'INSTAGRAM_LIST_SESSION_REDUCER_REJECTED';
export const INSTAGRAM_LIST_SESSION_REDUCER_RESET = 'INSTAGRAM_LIST_SESSION_REDUCER_RESET';
export const SET_PAGE_INSTAGRAM_LIST_SESSION = 'SET_PAGE_INSTAGRAM_LIST_SESSION';
export const SET_PAGE_TWITTER_LIST_SESSION = 'SET_PAGE_TWITTER_LIST_SESSION';
export const LINKEDIN_LIST_SESSION_REDUCER_PENDING = 'LINKEDIN_LIST_SESSION_REDUCER_PENDING';
export const LINKEDIN_LIST_SESSION_REDUCER_FULLFILLED = 'LINKEDIN_LIST_SESSION_REDUCER_FULLFILLED';
export const LINKEDIN_LIST_SESSION_REDUCER_REJECTED = 'LINKEDIN_LIST_SESSION_REDUCER_REJECTED';
export const LINKEDIN_LIST_SESSION_REDUCER_RESET = 'LINKEDIN_LIST_SESSION_REDUCER_RESET';
export const SET_PAGE_LINKEDIN_LIST_SESSION = 'SET_PAGE_LINKEDIN_LIST_SESSION';

const stateInitiaTwitter = {
  isLoading: false,
  error: false,
  result: {},
};

const stateInitiaFacebook = {
  isLoading: false,
  error: false,
  result: {
    V_TOTAL: 0,
  },
};

const stateInitiaInstagram = {
  isLoading: false,
  error: false,
  result: {
    V_TOTAL: 0,
  },
};

const stateInitiaLinkedin = {
  isLoading: false,
  error: false,
  result: {
    V_TOTAL: 0,
  },
};

const stateListInstagram = {
  isLoading: false,
  error: false,
  page: 1,
  limit: 50,
  total: 0,
  result: {
    data: [],
  },
};

const stateListLinkedin = {
  isLoading: false,
  error: false,
  page: 1,
  limit: 50,
  total: 0,
  result: {
    data: [],
  },
};

export function TwitterNumberReducer(state = stateInitiaTwitter, action) {
  switch (action.type) {
    case TWITTER_NUMBER_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, result: { V_TOTAL: 0 },
      };
    case TWITTER_NUMBER_REDUCER_FULLFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case TWITTER_NUMBER_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, result: { V_TOTAL: 0 },
      };
    default:
      return state;
  }
}
export function FacebookNumberReducer(state = stateInitiaFacebook, action) {
  switch (action.type) {
    case FACEBOOK_NUMBER_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, result: { V_TOTAL: 0 },
      };
    case FACEBOOK_NUMBER_REDUCER_FULLFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case FACEBOOK_NUMBER_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, result: { V_TOTAL: 0 },
      };
    default:
      return state;
  }
}
export function InstagramNumberReducer(state = stateInitiaInstagram, action) {
  switch (action.type) {
    case INSTAGRAM_NUMBER_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, result: { V_TOTAL: 0 },
      };
    case INSTAGRAM_NUMBER_REDUCER_FULLFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case INSTAGRAM_NUMBER_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, result: { V_TOTAL: 0 },
      };
    default:
      return state;
  }
}
export function LinkedinNumberReducer(state = stateInitiaLinkedin, action) {
  switch (action.type) {
    case LINKEDIN_NUMBER_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, result: { V_TOTAL: 0 },
      };
    case LINKEDIN_NUMBER_REDUCER_FULLFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case LINKEDIN_NUMBER_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, result: { V_TOTAL: 0 },
      };
    default:
      return state;
  }
}
export function InstagramListSession(state = stateListInstagram, action) {
  switch (action.type) {
    case INSTAGRAM_LIST_SESSION_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, page: 1, result: { data: [] }, total: 0,
      };
    case INSTAGRAM_LIST_SESSION_REDUCER_FULLFILLED:
      return {
        ...state, isLoading: false, result: { data: action.payload }, total: action.total,
      };
    case INSTAGRAM_LIST_SESSION_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, page: 1, result: { data: [] },
      };
    case SET_PAGE_INSTAGRAM_LIST_SESSION:
      return { ...state, page: action.payload };
    case INSTAGRAM_LIST_SESSION_REDUCER_RESET:
      return {
        ...state, isLoading: false, error: false, page: 1, result: { data: [] }, total: 0,
      };
    default:
      return state;
  }
}
export function LinkedinListSession(state = stateListLinkedin, action) {
  switch (action.type) {
    case LINKEDIN_LIST_SESSION_REDUCER_PENDING:
      return {
        ...state, isLoading: true, error: false, page: 1, result: { data: [] }, total: 0,
      };
    case LINKEDIN_LIST_SESSION_REDUCER_FULLFILLED:
      return {
        ...state, isLoading: false, result: { data: action.payload }, total: action.total,
      };
    case LINKEDIN_LIST_SESSION_REDUCER_REJECTED:
      return {
        ...state, isLoading: false, error: true, page: 1, result: { data: [] },
      };
    case SET_PAGE_LINKEDIN_LIST_SESSION:
      return { ...state, page: action.payload };
    case LINKEDIN_LIST_SESSION_REDUCER_RESET:
      return {
        ...state, isLoading: false, error: false, page: 1, result: { data: [] }, total: 0,
      };
    default:
      return state;
  }
}

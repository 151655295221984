const CandidateState = {
  C_SOSMED_ID: '',
  C_PERSONAL_ID: '',
  V_SOSMED_URL: '',
  V_SOSMED_USERNAME: '',
  isModal: false,
};
const ActionState = {
  isLoading: false,
  error: false,
};
const ProfileFacebookState = {
  ...ActionState,
  result: {
    V_FRIEND_COUNT: '-',
    V_FULLNAME: '',
    V_PROFILE_IMAGE_URL: '',
    V_TOTAL_POST: '-',
    V_USERNAME: '',
  },
};
const ProfileInstagramState = {
  ...ActionState,
  result: {
    V_FOLLOWER_COUNT: 0,
    V_FOLLOWING_COUNT: 0,
    V_FULLNAME: '',
    V_PROFILE_IMAGE_URL: '',
    V_TOTAL_POST: 0,
    V_USERNAME: '',
  },
};
const ProfileTwitterState = {
  ...ActionState,
  result: {
    C_STATUS_ID: 0,
    V_FRIENDS_COUNT: 0,
    V_FULLNAME: '',
    V_PROFILE_IMAGE_URL: '',
    V_TOTAL_POST: 0,
    V_USERNAME: '',
  },
};
const PostTwitterState = {
  ...ActionState,
  result: {
    data: {},
    dataCount: {},
  },
};
const PostInstagramState = {
  ...ActionState,
  result: {
    data: {},
    dataCount: {},
  },
};
const PostFacebookState = {
  ...ActionState,
  result: {
    data: {},
    dataCount: {},
  },
};
const CountPostState = {
  ...ActionState,
  result: {
    facebookAll: 0,
    instagramAll: 0,
    twitterAll: 0,
    facebookTrained: 0,
    instagramTrained: 0,
    twitterTrained: 0,
  },
};
const CountQueueState = {
  ...ActionState,
  result: {
    instagram: {},
    facebook: {},
    twitter: {},
    linkedin: {},
  },
};
const MonitorState = {
  ...ActionState,
  result: {
    instagram: [],
    facebook: [],
    twitter: [],
  },
};
const ListLinkedinState = {
  ...ActionState,
  result: [],
};
const TwitterPrivateAccountState = {
  ...ActionState,
  result: [],
};
const InstagramPrivateAccountState = {
  ...ActionState,
  result: [],
};

const InstagramScraperNewDataByBatch = {
  ...ActionState,
  result: [],
};
const InstagramScraperInvalidByBatch = {
  ...ActionState,
  result: [],
};

const TwitterScraperNewDataByBatch = {
  ...ActionState,
  result: [],
};

const TwitterScraperInvalidBatch = {
  ...ActionState,
  result: [],
};

const FacebookScraperNewDataByBatch = {
  ...ActionState,
  result: [],
};
const FacebookScraperInvalidByBatch = {
  ...ActionState,
  result: [],
};
const FacebookScraperPrivateByBatch = {
  ...ActionState,
  result: [],
};

const LinkedinScraperNewDataByBatch = {
  ...ActionState,
  result: [],
};
const LinkedinScraperInvalidByBatch = {
  ...ActionState,
  result: [],
};
const LinkedinScraperPrivateByBatch = {
  ...ActionState,
  result: [],
};
const LinkedinScraperPublicByBatch = {
  ...ActionState,
  result: [],
};
const TwitterScraperPublicByBatch = {
  ...ActionState,
  result: [],
};
const InstagramScraperPublicByBatch = {
  ...ActionState,
  result: [],
};
const FacebookScraperPublicByBatch = {
  ...ActionState,
  result: [],
};
export function CandidateEditReducer(state =
CandidateState, action) {
  let V_SOSMED_URL_EDIT = '';
  switch (action.type) {
    case 'CANDIDATE_EDIT_REDUCER_FULFILLED':
      if (action.C_SOSMED_ID === 'instagram') {
        V_SOSMED_URL_EDIT = `https://www.instagram.com/${action.V_SOSMED_USERNAME}`;
      }
      if (action.C_SOSMED_ID === 'linkedin') {
        V_SOSMED_URL_EDIT = `https://www.linkedin.com/in/${action.V_SOSMED_USERNAME}`;
      }
      if (action.C_SOSMED_ID === 'facebook') {
        V_SOSMED_URL_EDIT = `https://www.facebook.com/${action.V_SOSMED_USERNAME}`;
      }
      return {
        ...state,
        C_SOSMED_ID: action.C_SOSMED_ID,
        C_PERSONAL_ID: action.C_PERSONAL_ID,
        V_SOSMED_URL: V_SOSMED_URL_EDIT,
        V_SOSMED_USERNAME: action.V_SOSMED_USERNAME,
        isModal: true,
      };
    case 'CANDIDATE_EDIT_MODAL_CLOSE':
      return {
        ...state,
        C_SOSMED_ID: '',
        C_PERSONAL_ID: '',
        V_SOSMED_URL: '',
        V_SOSMED_USERNAME: '',
        isModal: false,
      };
    default:
      return state;
  }
}
// checked twitter
const x = JSON.parse(localStorage.getItem('persist:root'));
const dataChekedState = x?.DataChekedReducer
  ? JSON.parse(x.DataChekedReducer) : {
    isLoading: false,
    error: false,
    C_BATCH_ID: '',
    sosemed: 'twitter',
    result: [],
    resultAllUntrained: [],
    resultAllUntrainedSubmit: [],
    dataCount: 0,
    index: 0,
    indexObject: {},
  };

// checked facebook
const dataChekedStateFacebook = x?.DataChekedReducerFacebook
  ? JSON.parse(x.DataChekedReducerFacebook) : {
    isLoading: false,
    error: false,
    C_BATCH_ID: '',
    sosemed: 'facebook',
    result: [],
    resultAllUntrained: [],
    resultAllUntrainedSubmit: [],
    dataCount: 0,
    index: 0,
    indexObject: {},
  };

// checked Instagram
const dataChekedStateInstagram = x?.DataChekedReducerInstagram
  ? JSON.parse(x.DataChekedReducerFacebook) : {
    isLoading: false,
    error: false,
    C_BATCH_ID: '',
    sosemed: 'facebook',
    result: [],
    resultAllUntrained: [],
    resultAllUntrainedSubmit: [],
    dataCount: 0,
    index: 0,
    indexObject: {},
  };

export function DataChekedReducer(state = dataChekedState, action) {
  switch (action.type) {
    case 'DATA_CHECKED_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: [...action.payload],
      };
    case 'DATA_All_LIST_UNDTRAINED':
      return {
        ...state,
        isLoading: false,
        resultAllUntrained: [...action.payload],
        result: [],
        resultAllUntrainedSubmit: [],
        dataCount: action.dataCount,
        index: 0,
        C_BATCH_ID: action.C_BATCH_ID,
        indexObject: {},
      };
    case 'DATA_CHECKED_UPDATE': {
      const objIndex = state.result.findIndex((item) => item.index === action.payload.index);
      const updatedProjects = [...state.result.slice(0, objIndex),
        action.payload, ...state.result.slice(objIndex + 1)];
      const arrayChecked = [];
      updatedProjects.find((res) => {
        const s = '';
        if (res.checked === true) {
          arrayChecked.push(res);
        }
        return s;
      });
      return {
        ...state,
        isLoading: false,
        result: updatedProjects,
        resultAllUntrainedSubmit: [...arrayChecked],
      };
    }
    case 'AddCheckedSubmit':
      return {
        ...state,
        isLoading: false,
        resultAllUntrainedSubmit: [...action.payload],
      };
    case 'SetIndex':
      return {
        ...state,
        index: action.payload,
      };
    case 'SETINDEXOBJECT':
      return {
        ...state,
        indexObject: action.payload,
      };
    default:
      return state;
  }
}
export function DataChekedReducerFacebook(state = dataChekedStateFacebook, action) {
  switch (action.type) {
    case 'DATA_CHECKED_REDUCER_FACEBOOK_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: [...action.payload],
      };
    case 'DATA_All_LIST_FACEBOOK_UNDTRAINED':
      return {
        ...state,
        isLoading: false,
        resultAllUntrained: [...action.payload],
        result: [],
        resultAllUntrainedSubmit: [],
        dataCount: action.dataCount,
        index: 0,
        C_BATCH_ID: action.C_BATCH_ID,
        indexObject: {},
      };
    case 'DATA_CHECKED_FACEBOOK_UPDATE': {
      const objIndex = state.result.findIndex((item) => item.index === action.payload.index);
      const updatedProjects = [...state.result.slice(0, objIndex),
        action.payload, ...state.result.slice(objIndex + 1)];
      const arrayChecked = [];
      updatedProjects.find((res) => {
        const s = '';
        if (res.checked === true) {
          arrayChecked.push(res);
        }
        return s;
      });
      return {
        ...state,
        isLoading: false,
        result: updatedProjects,
        resultAllUntrainedSubmit: [...arrayChecked],
      };
    }
    case 'AddCheckedFACEBOOKSubmit':
      return {
        ...state,
        isLoading: false,
        resultAllUntrainedSubmit: [...action.payload],
      };
    case 'SetIndexFACEBOOK':
      return {
        ...state,
        index: action.payload,
      };
    case 'SETINDEXOBJECTFACEBOOK':
      return {
        ...state,
        indexObject: action.payload,
      };
    default:
      return state;
  }
}
export function DataChekedReducerInstagram(state = dataChekedStateInstagram, action) {
  switch (action.type) {
    case 'DATA_CHECKED_REDUCER_INSTAGRAM_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: [...action.payload],
      };
    case 'DATA_All_LIST_INSTAGRAM_UNDTRAINED':
      return {
        ...state,
        isLoading: false,
        resultAllUntrained: [...action.payload],
        result: [],
        resultAllUntrainedSubmit: [],
        dataCount: action.dataCount,
        index: 0,
        C_BATCH_ID: action.C_BATCH_ID,
        indexObject: {},
      };
    case 'DATA_CHECKED_INSTAGRAM_UPDATE': {
      const objIndex = state.result.findIndex((item) => item.index === action.payload.index);
      const updatedProjects = [...state.result.slice(0, objIndex),
        action.payload, ...state.result.slice(objIndex + 1)];
      const arrayChecked = [];
      updatedProjects.find((res) => {
        const s = '';
        if (res.checked === true) {
          arrayChecked.push(res);
        }
        return s;
      });
      return {
        ...state,
        isLoading: false,
        result: updatedProjects,
        resultAllUntrainedSubmit: [...arrayChecked],
      };
    }
    case 'AddCheckedINSTAGRAMSubmit':
      return {
        ...state,
        isLoading: false,
        resultAllUntrainedSubmit: [...action.payload],
      };
    case 'SetIndexINSTAGRAM':
      return {
        ...state,
        index: action.payload,
      };
    case 'SETINDEXOBJECTINSTAGRAM':
      return {
        ...state,
        indexObject: action.payload,
      };
    default:
      return state;
  }
}
export function InstagramScraperNewDataByBatchReducer(state =
InstagramScraperNewDataByBatch, action) {
  switch (action.type) {
    case 'IG_SCRAPER_NEW_DATA_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'IG_SCRAPER_NEW_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'IG_SCRAPER_NEW_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function InstagramScraperInvalidByBatchReducer(state =
InstagramScraperInvalidByBatch, action) {
  switch (action.type) {
    case 'IG_SCRAPER_INVALID_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'IG_SCRAPER_INVALID_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'IG_SCRAPER_INVALID_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TwitterScraperNewDataByBatchReducer(state = TwitterScraperNewDataByBatch, action) {
  switch (action.type) {
    case 'TW_SCRAPER_NEW_DATA_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'TW_SCRAPER_NEW_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TW_SCRAPER_NEW_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TwitterScraperInvalidBatchReducer(state = TwitterScraperInvalidBatch, action) {
  switch (action.type) {
    case 'TW_SCRAPER_INVALID_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'TW_SCRAPER_INVALID_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TW_SCRAPER_INVALID_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function FacebookScraperNewDataByBatchReducer(state =
FacebookScraperNewDataByBatch, action) {
  switch (action.type) {
    case 'FB_SCRAPER_NEW_DATA_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'FB_SCRAPER_NEW_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'FB_SCRAPER_NEW_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function FacebookScraperInvalidBatchReducer(state =
FacebookScraperInvalidByBatch, action) {
  switch (action.type) {
    case 'FB_SCRAPER_INVALID_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'FB_SCRAPER_INVALID_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'FB_SCRAPER_INVALID_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function FacebookScraperPrivateBatchReducer(state =
FacebookScraperPrivateByBatch, action) {
  switch (action.type) {
    case 'FB_SCRAPER_PRIVATE_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'FB_SCRAPER_PRIVATE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'FB_SCRAPER_PRIVATE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function LinkedinScraperNewDataByBatchReducer(state =
LinkedinScraperNewDataByBatch, action) {
  switch (action.type) {
    case 'Linkedin_SCRAPER_NEW_DATA_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'Linkedin_SCRAPER_NEW_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'Linkedin_SCRAPER_NEW_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function LinkedinScraperInvalidByBatchReducer(state =
LinkedinScraperInvalidByBatch, action) {
  switch (action.type) {
    case 'Linkedin_SCRAPER_INVALID_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'Linkedin_SCRAPER_INVALID_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'Linkedin_SCRAPER_INVALID_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function LinkedinScraperPrivateByBatchReducer(state =
LinkedinScraperPrivateByBatch, action) {
  switch (action.type) {
    case 'Linkedin_SCRAPER_PRIVATE_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'Linkedin_SCRAPER_PRIVATE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'Linkedin_SCRAPER_PRIVATE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ProfileFacebookReducer(state = ProfileFacebookState, action) {
  switch (action.type) {
    case 'PROFILE_FACEBOOK_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'PROFILE_FACEBOOK_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: { ...state.result, ...action.payload[0] } };
    case 'PROFILE_FACEBOOK_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ProfileInstagramReducer(state = ProfileInstagramState, action) {
  switch (action.type) {
    case 'PROFILE_INSTAGRAM_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'PROFILE_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: { ...state.result, ...action.payload[0] } };
    case 'PROFILE_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ProfileTwitterReducer(state = ProfileTwitterState, action) {
  switch (action.type) {
    case 'PROFILE_TWITTER_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'PROFILE_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: { ...state.result, ...action.payload[0] } };
    case 'PROFILE_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostTwitterTrainedReducer(state = PostTwitterState, action) {
  switch (action.type) {
    case 'POST_TWITTER_TRAINED_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: {} },
      };
    case 'POST_TWITTER_TRAINED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'POST_TWITTER_TRAINED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostInstagramTrainedReducer(state = PostInstagramState, action) {
  switch (action.type) {
    case 'POST_INSTAGRAM_TRAINED_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: {} },
      };
    case 'POST_INSTAGRAM_TRAINED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'POST_INSTAGRAM_TRAINED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostFacebookTrainedReducer(state = PostFacebookState, action) {
  switch (action.type) {
    case 'POST_FACEBOOK_TRAINED_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: {} },
      };
    case 'POST_FACEBOOK_TRAINED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'POST_FACEBOOK_TRAINED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostTwitterUntrainedReducer(state = PostTwitterState, action) {
  switch (action.type) {
    case 'POST_TWITTER_UNTRAINED_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: {} },
      };
    case 'POST_TWITTER_UNTRAINED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'POST_TWITTER_UNTRAINED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostInstagramUntrainedReducer(state = PostInstagramState, action) {
  switch (action.type) {
    case 'POST_INSTAGRAM_UNTRAINED_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: {} },
      };
    case 'POST_INSTAGRAM_UNTRAINED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'POST_INSTAGRAM_UNTRAINED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostFacebookUntrainedReducer(state = PostFacebookState, action) {
  switch (action.type) {
    case 'POST_FACEBOOK_UNTRAINED_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: { data: [], dataCount: {} },
      };
    case 'POST_FACEBOOK_UNTRAINED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'POST_FACEBOOK_UNTRAINED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostTwitterTrainedBatchReducer(state = PostTwitterState, action) {
  switch (action.type) {
    case 'POST_TWITTER_TRAINED_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'POST_TWITTER_TRAINED_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'POST_TWITTER_TRAINED_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'POST_TWITTER_TRAINED_BATCH_REDUCER_RESET_ALL':
      return PostTwitterState;
    default:
      return state;
  }
}
export function PostInstagramTrainedBatchReducer(state = PostInstagramState, action) {
  switch (action.type) {
    case 'POST_INSTAGRAM_TRAINED_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'POST_INSTAGRAM_TRAINED_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'POST_INSTAGRAM_TRAINED_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'POST_INSTAGRAM_TRAINED_BATCH_REDUCER_RESET_ALL':
      return PostInstagramState;
    default:
      return state;
  }
}
export function PostFacebookTrainedBatchReducer(state = PostFacebookState, action) {
  switch (action.type) {
    case 'POST_FACEBOOK_TRAINED_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'POST_FACEBOOK_TRAINED_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'POST_FACEBOOK_TRAINED_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'POST_FACEBOOK_TRAINED_BATCH_REDUCER_RESET_ALL':
      return PostFacebookState;
    default:
      return state;
  }
}
export function PostTwitterUntrainedBatchReducer(state = PostTwitterState, action) {
  switch (action.type) {
    case 'POST_TWITTER_UNTRAINED_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'POST_TWITTER_UNTRAINED_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'POST_TWITTER_UNTRAINED_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'POST_TWITTER_UNTRAINED_BATCH_REDUCER_RESET_ALL':
      return PostTwitterState;
    default:
      return state;
  }
}
export function PostInstagramUntrainedBatchReducer(state = PostInstagramState, action) {
  switch (action.type) {
    case 'POST_INSTAGRAM_UNTRAINED_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'POST_INSTAGRAM_UNTRAINED_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'POST_INSTAGRAM_UNTRAINED_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'POST_INSTAGRAM_UNTRAINED_BATCH_REDUCER_RESET_ALL':
      return PostInstagramState;
    default:
      return state;
  }
}
export function PostFacebookUntrainedBatchReducer(state = PostFacebookState, action) {
  switch (action.type) {
    case 'POST_FACEBOOK_UNTRAINED_BATCH_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false,
      };
    case 'POST_FACEBOOK_UNTRAINED_BATCH_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          data: {
            ...state.result.data,
            ...action.payload.data,
          },
          dataCount: action.payload.dataCount,
        },
      };
    case 'POST_FACEBOOK_UNTRAINED_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'POST_FACEBOOK_UNTRAINED_BATCH_REDUCER_RESET_ALL':
      return PostFacebookState;
    default:
      return state;
  }
}
export function CountPostReducer(state = CountPostState, action) {
  switch (action.type) {
    case 'COUNT_POST_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'COUNT_POST_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'COUNT_POST_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function CountQueueReducer(state = CountQueueState, action) {
  switch (action.type) {
    case 'COUNT_QUEUE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'COUNT_QUEUE_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result, [action.payload.key]: action.payload.value,
        },
      };
    case 'COUNT_QUEUE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MonitorReducer(state = MonitorState, action) {
  switch (action.type) {
    case 'MONITOR_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MONITOR_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result, [action.payload.key]: action.payload.value,
        },
      };
    case 'MONITOR_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ListLinkedinReducer(state = ListLinkedinState, action) {
  switch (action.type) {
    case 'LIST_LINKEDIN_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'LIST_LINKEDIN_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LIST_LINKEDIN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TwitterPrivateAccountReducer(state = TwitterPrivateAccountState, action) {
  switch (action.type) {
    case 'TWITTER_ACCOUNT_PRIVATE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TWITTER_ACCOUNT_PRIVATE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TWITTER_ACCOUNT_PRIVATE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function InstagramPrivateAccountReducer(state = InstagramPrivateAccountState, action) {
  switch (action.type) {
    case 'INSTAGRAM_ACCOUNT_PRIVATE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'INSTAGRAM_ACCOUNT_PRIVATE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'INSTAGRAM_ACCOUNT_PRIVATE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function FacebookScraperPublicByBatchReducer(state =
FacebookScraperPublicByBatch, action) {
  switch (action.type) {
    case 'FB_SCRAPER_PUBLIC_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'FB_SCRAPER_PUBLIC_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'FB_SCRAPER_PUBLIC_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function InstagramScraperPublicByBatchReducer(state =
InstagramScraperPublicByBatch, action) {
  switch (action.type) {
    case 'INSTAGRAM_SCRAPER_PUBLIC_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'INSTAGRAM_SCRAPER_PUBLIC_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'INSTAGRAM_SCRAPER_PUBLIC_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TwitterScraperPublicByBatchReducer(state =
TwitterScraperPublicByBatch, action) {
  switch (action.type) {
    case 'TWITTER_SCRAPER_PUBLIC_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'TWITTER_SCRAPER_PUBLIC_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TWITTER_SCRAPER_PUBLIC_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function LinkedinScraperPublicByBatchReducer(state =
LinkedinScraperPublicByBatch, action) {
  switch (action.type) {
    case 'LINKEDIN_SCRAPER_PUBLIC_REDUCER_PENDING':
      return {
        ...state, isLoading: true, error: false, result: state.result,
      };
    case 'LINKEDIN_SCRAPER_PUBLIC_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'LINKEDIN_SCRAPER_PUBLIC_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

const baseState = {
  isLoading: false,
  error: false,
};

const monitorBatchState = {
  ...baseState,
  result: [],
};

const monitorBatchDetailState = {
  ...baseState,
  result: {
    uploaded: {},
    scraped: {},
    analyzed: {},
    er: {},
    validated: {},
    reported: {},
  },
};

const monitorScrapingState = {
  ...baseState,
  result: {
    facebook: [],
    instagram: [],
    twitter: [],
  },
};

const monitorCandidateState = {
  ...baseState,
  result: [],
};

const countBatchMonitorState = {
  ...baseState,
  result: {},
};

const topQuotaWOWillExpState = {
  ...baseState,
  result: {},
};

const monitorBatchDetailStatusState = {
  ...baseState,
  result: {
    uploaded: 'not-start',
    scraped: 'not-start',
    analyzed: 'not-start',
    er: 'not-start',
    validated: 'not-start',
    reported: 'not-start',
  },
};

const monitorStatusAnalyzeEnginee = {
  isLoading: false,
  error: false,
  data: {
    intel: {
      igDataOngoing: 0,
      igDataDone: 0,
      twtDataOngoing: 0,
      twtDataDone: 0,
      fbDataOngoing: 0,
      fbDataDone: 0,
    },
    neon: {
      igDataOngoing: 0,
      igDataDone: 0,
      twtDataOngoing: 0,
      twtDataDone: 0,
      fbDataOngoing: 0,
      fbDataDone: 0,
    },
    porpal: {
      igDataOngoing: 0,
      igDataDone: 0,
      twtDataOngoing: 0,
      twtDataDone: 0,
      fbDataOngoing: 0,
      fbDataDone: 0,
    },
  },
};

export function MonitorBatchAnalyzeEnginee(state = monitorStatusAnalyzeEnginee, action) {
  switch (action.type) {
    case 'MONITOR_BATCH_ANALYZE_ENGINEE_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        data: {
          intel: {
            igDataOngoing: 0,
            igDataDone: 0,
            twtDataOngoing: 0,
            twtDataDone: 0,
            fbDataOngoing: 0,
            fbDataDone: 0,
          },
          neon: {
            igDataOngoing: 0,
            igDataDone: 0,
            twtDataOngoing: 0,
            twtDataDone: 0,
            fbDataOngoing: 0,
            fbDataDone: 0,
          },
          porpal: {
            igDataOngoing: 0,
            igDataDone: 0,
            twtDataOngoing: 0,
            twtDataDone: 0,
            fbDataOngoing: 0,
            fbDataDone: 0,
          },
        },
      };
    case 'MONITOR_BATCH_ANALYZE_ENGINEE_FULFILLED':
      return { ...state, isLoading: false, data: action.payload };
    case 'MONITOR_BATCH_ANALYZE_ENGINEE_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MonitorBatchDetailReducer(state = monitorBatchDetailState, action) {
  switch (action.type) {
    case 'MONITOR_BATCH_DETAIL_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: monitorBatchDetailState.result,
      };
    case 'MONITOR_BATCH_DETAIL_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MONITOR_BATCH_DETAIL_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MonitorBatchReducer(state = monitorBatchState, action) {
  switch (action.type) {
    case 'MONITOR_BATCH_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: monitorBatchState.result,
      };
    case 'MONITOR_BATCH_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MONITOR_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MonitorScrapingReducer(state = monitorScrapingState, action) {
  switch (action.type) {
    case 'MONITOR_SCRAPING_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'MONITOR_SCRAPING_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          [action.payload.key]: action.payload.value,
        },
      };
    case 'MONITOR_SCRAPING_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function MonitorCandidateReducer(state = monitorCandidateState, action) {
  switch (action.type) {
    case 'MONITOR_CANDIDATE_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: monitorCandidateState.result,
      };
    case 'MONITOR_CANDIDATE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'MONITOR_CANDIDATE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MonitorBatchDetailStatusReducer(state = monitorBatchDetailStatusState, action) {
  switch (action.type) {
    case 'MONITOR_BATCH_DETAIL_STATUS_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: monitorBatchDetailStatusState.result,
      };
    case 'MONITOR_BATCH_DETAIL_STATUS_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        error: false,
        result: action.payload,
      };
    case 'MONITOR_BATCH_DETAIL_STATUS_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: false };
    default:
      return state;
  }
}

export function CountBatchAllMonitorReducer(state = countBatchMonitorState, action) {
  switch (action.type) {
    case 'COUNT_BATCH_ALL_MONITOR_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: countBatchMonitorState.result,
      };
    case 'COUNT_BATCH_ALL_MONITOR_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'COUNT_BATCH_ALL_MONITOR_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function GetTopQuotaWorkorderWillExpiredReducer(state = topQuotaWOWillExpState, action) {
  switch (action.type) {
    case 'COUNT_TOPQUOTAWORKORDER_WILLEXPIRED_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: topQuotaWOWillExpState.result,
      };
    case 'COUNT_TOPQUOTAWORKORDER_WILLEXPIRED_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'COUNT_TOPQUOTAWORKORDER_WILLEXPIRED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

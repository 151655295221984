import * as PropertiesReducer from '../reducers/_propertiesReducer';
import * as BatcesReducer from '../reducers/_batchesReducer';
import * as SosmedReducer from '../reducers/_sosmedReducer';
import * as MasterReducer from '../reducers/_masterReducer';
import * as TrainReducer from '../reducers/_trainReducer';
import * as MonitorReducer from '../reducers/_monitorReducer';
import * as NotificationReducer from '../reducers/_notificationReducer';
import {
  ListPromoReducer,
  PropertiesPROMO,
  DetailPromoReducer,
  PropertyPromoCode,
} from '../reducers/_PromoCode';
import {
  TwitterNumberReducer,
  FacebookNumberReducer,
  InstagramNumberReducer,
  LinkedinNumberReducer,
  InstagramListSession,
  LinkedinListSession,
} from '../reducers/_queuelist';

export default {
  ...PropertiesReducer,
  ...BatcesReducer,
  ...SosmedReducer,
  ...MasterReducer,
  ...TrainReducer,
  ...MonitorReducer,
  ...NotificationReducer,
  ListPromoReducer,
  PropertiesPROMO,
  DetailPromoReducer,
  PropertyPromoCode,
  TwitterNumberReducer,
  FacebookNumberReducer,
  InstagramNumberReducer,
  LinkedinNumberReducer,
  InstagramListSession,
  LinkedinListSession,
};
